import axios from 'axios';
import store from '../store';
import updateTokenFromRes from '../utils/updateTokenFromRes';

export default async (requestMethod, url, data, config) => {
  let res;
  switch (requestMethod) {
    case 'get':
    case 'delete':
      res = await axios[requestMethod](url, config);
      break;
    default:
      res = await axios[requestMethod](url, data, config);
  }
  store.dispatch(updateTokenFromRes(res));
  return res;
};
