import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  AUTH_UPDATE_SUCCESS,
  INIT_FORGOT_PW_PAGE,
  FORGOT_PW_REQUEST_SENT,
  START_VERIFY_RESET_PW_TOKEN,
  VERIFY_RESET_PW_TOKEN_SUCCESS,
  VERIFY_RESET_PW_TOKEN_FAILED,
  RESET_PW_SUCCESS,
} from '../actions/types';
import { localStorageKeys } from '../utils/const';
import userEnum from '../enum/user';

const { AUTH_TOKEN: KEY_AUTH_TOKEN } = localStorageKeys;

const initialState = {
  token: localStorage.getItem(KEY_AUTH_TOKEN),
  isAuthenticated: null,
  isAdmin: null,
  isKS: null,
  loading: true,
  user: null,
  forgotPWRequestSent: false,
  verifyingResetPWToken: false,
  resetPWTokenValid: false,
  resetPWSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isAdmin: payload.role === userEnum.role.admin,
        isKS: payload.role === userEnum.role.ks,
        loading: false,
        user: payload,
      };
    case LOGIN_SUCCESS:
    case AUTH_UPDATE_SUCCESS:
      localStorage.setItem(KEY_AUTH_TOKEN, payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case AUTH_ERROR:
    case LOGIN_FAILED:
    case LOGOUT:
      localStorage.removeItem(KEY_AUTH_TOKEN);
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isAdmin: null,
        isKS: null,
        loading: false,
      };
    case INIT_FORGOT_PW_PAGE:
      return {
        ...state,
        forgotPWRequestSent: false,
      };
    case FORGOT_PW_REQUEST_SENT:
      return {
        ...state,
        forgotPWRequestSent: true,
      };
    case START_VERIFY_RESET_PW_TOKEN:
      return {
        ...state,
        verifyingResetPWToken: true,
        resetPWSuccess: false,
      };
    case VERIFY_RESET_PW_TOKEN_SUCCESS:
      return {
        ...state,
        verifyingResetPWToken: false,
        resetPWTokenValid: true,
      };
    case VERIFY_RESET_PW_TOKEN_FAILED:
      return {
        ...state,
        verifyingResetPWToken: false,
        resetPWTokenValid: false,
      };
    case RESET_PW_SUCCESS:
      return {
        ...state,
        resetPWSuccess: true,
      };
    default:
      return state;
  }
};
