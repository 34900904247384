import sendRequest from '../utils/sendRequest';
import handleResErr from '../utils/handleResErr';
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  INIT_FORGOT_PW_PAGE,
  FORGOT_PW_REQUEST_SENT,
  START_VERIFY_RESET_PW_TOKEN,
  VERIFY_RESET_PW_TOKEN_SUCCESS,
  VERIFY_RESET_PW_TOKEN_FAILED,
  RESET_PW_SUCCESS
} from './types';
import setAuthToken from '../utils/setAuthToken';
import { localStorageKeys } from '../utils/const';
import { addUILoading, removeUILoading } from './ui';
import { removeAllAlerts, createAlert } from '../actions/alert';

// Load User
export const loadUser = () => async dispatch => {
  dispatch(addUILoading());
  const { AUTH_TOKEN: KEY_AUTH_TOKEN } = localStorageKeys;
  const storedAuthToken = localStorage.getItem(KEY_AUTH_TOKEN);
  if (storedAuthToken) {
    setAuthToken(storedAuthToken);

    try {
      const res = await sendRequest('get', '/api/user/auth');

      dispatch(removeUILoading());
      return dispatch({
        type: USER_LOADED,
        payload: res.data
      });
    } catch (err) {}
  }

  dispatch(removeUILoading());
  dispatch({
    type: AUTH_ERROR
  });
};

// Login User
export const login = (loginName, password, rememberMe) => async dispatch => {
  dispatch(removeAllAlerts());
  dispatch(addUILoading());

  const body = JSON.stringify({ loginName, password });
  try {
    const res = await sendRequest('post', '/api/user/auth', body);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });
    const { LOGIN_NAME: KEY_LOGIN_NAME } = localStorageKeys;
    if (rememberMe) {
      localStorage.setItem(KEY_LOGIN_NAME, loginName);
    } else {
      localStorage.removeItem(KEY_LOGIN_NAME);
    }
    dispatch(loadUser());
  } catch (err) {
    dispatch(handleResErr(err, { handleAuthDenied: false }));
    dispatch({ type: LOGIN_FAILED });
  }
  dispatch(removeUILoading());
};

export const verifyRecaptchaAndLogin = (
  token,
  loginName,
  password,
  rememberMe
) => async dispatch => {
  dispatch(removeAllAlerts());
  dispatch(addUILoading());
  try {
    await sendRequest('post', '/api/recaptcha/verify', JSON.stringify({ token }));
    dispatch(login(loginName, password, rememberMe));
  } catch (err) {
    if (err.response && err.response.status === 400) {
      dispatch(createAlert('RECAPTCHA_VERIFY_FAILED'));
    }
  }
  dispatch(removeUILoading());
};

// Logout / Clear Profile
export const logout = () => dispatch => {
  dispatch({ type: LOGOUT });
};

export const initForgotPWPage = () => async dispatch => {
  dispatch({ type: INIT_FORGOT_PW_PAGE });
};

export const submitForgotPW = email => async dispatch => {
  dispatch(removeAllAlerts());
  dispatch(addUILoading());
  try {
    await sendRequest('post', '/api/user/forgot_pw', JSON.stringify({ email }));
    dispatch({
      type: FORGOT_PW_REQUEST_SENT
    });
  } catch (err) {
    dispatch(handleResErr(err, { handleAuthDenied: false }));
  }
  dispatch(removeUILoading());
};

export const verifyResetPWToken = token => async dispatch => {
  dispatch(removeAllAlerts());
  dispatch(addUILoading());
  dispatch({
    type: START_VERIFY_RESET_PW_TOKEN
  });
  try {
    await sendRequest('post', '/api/user/reset_pw/verify', JSON.stringify({ token }));
    dispatch({
      type: VERIFY_RESET_PW_TOKEN_SUCCESS
    });
  } catch (err) {
    dispatch({
      type: VERIFY_RESET_PW_TOKEN_FAILED
    });
  }
  dispatch(removeUILoading());
};

export const submitResetPW = (token, newPassword, newPasswordConfirm) => async dispatch => {
  dispatch(removeAllAlerts());
  if (newPassword !== newPasswordConfirm) {
    dispatch(createAlert('RESET_PW_CONFIRM_PW_NOT_MATCH'));
    return;
  }
  dispatch(addUILoading());
  try {
    await sendRequest('post', '/api/user/reset_pw', JSON.stringify({ token, newPassword }));
    dispatch({
      type: RESET_PW_SUCCESS
    });
  } catch (err) {
    dispatch(handleResErr(err, { handleAuthDenied: false }));
  }
  dispatch(removeUILoading());
};
