import { ADD_UI_LOADING, REMOVE_UI_LOADING } from '../actions/types';

const initialState = {
  loadingCount: 0,
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case ADD_UI_LOADING:
      return {
        ...state,
        loadingCount: state.loadingCount + 1,
      };
    case REMOVE_UI_LOADING:
      return {
        ...state,
        loadingCount: Math.max(state.loadingCount - 1, 0),
      };
    default:
      return state;
  }
};
