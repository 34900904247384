import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { removeAlert } from '../../actions/alert';
import Translate from '../lang/Translate';

const AlertDismissible = ({ children, color, cbDismiss }) => {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => {
    setVisible(false);
    setTimeout(cbDismiss, 200); // the alert use 0.15s to fade out, callback after the transition
  };
  return (
    <Alert color={color} isOpen={visible} toggle={onDismiss}>
      {children}
    </Alert>
  );
};
AlertDismissible.propTypes = {
  color: PropTypes.string,
  cbDismiss: PropTypes.func.isRequired
};

const AlertContainer = ({ alerts, removeAlert }) => (
  <div id='alert-container'>
    {alerts !== null &&
      alerts.length > 0 &&
      alerts.map(alert => (
        <AlertDismissible
          key={alert.id}
          color={alert.alertType}
          cbDismiss={() => removeAlert(alert.id)}
        >
          <Translate msg={alert.msg} msgArgs={alert.msgArgs || undefined} />
        </AlertDismissible>
      ))}
  </div>
);

AlertContainer.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps, { removeAlert })(AlertContainer);
