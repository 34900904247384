import {
  START_LOADING_SUPPLIERS,
  SUPPLIERS_LOAD_SUCCESS,
  SUPPLIERS_LOAD_FAILED,
  SUPPLIERS_LOAD_SKIPPED,
  SUPPLIER_CREATE_SUCCESS,
  SUPPLIER_UPDATE_SUCCESS
} from '../actions/types';

const initialState = {
  loadingSuppliers: false,
  suppliers: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_LOADING_SUPPLIERS:
      return {
        ...state,
        loadingSuppliers: true
      };
    case SUPPLIERS_LOAD_SUCCESS:
      return {
        ...state,
        loadingSuppliers: false,
        suppliers: [...payload]
      };
    case SUPPLIERS_LOAD_FAILED:
    case SUPPLIERS_LOAD_SKIPPED:
      return {
        ...state,
        loadingSuppliers: false
      };
    case SUPPLIER_CREATE_SUCCESS:
      return {
        ...state,
        suppliers: []
      };
    case SUPPLIER_UPDATE_SUCCESS:
      return {
        ...state,
        suppliers: state.suppliers.map(v => {
          if (v._id === payload._id) {
            return payload;
          }
          return v;
        })
      };
    default:
      return state;
  }
};
