import { AUTH_UPDATE_SUCCESS } from '../actions/types';
import setAuthToken from '../utils/setAuthToken';

export default res => async dispatch => {
  if (res.headers && res.headers.authorization) {
    dispatch({
      type: AUTH_UPDATE_SUCCESS,
      payload: {
        token: res.headers.authorization
      }
    });
    setAuthToken(res.headers.authorization);
  }
};
