import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

export const LoadingOverlayComponent = props => (
  <div className='loading-overlay' {...props}>
    <Spinner style={{ width: '5rem', height: '5rem' }} />
  </div>
);

const LoadingOverlay = ({ ui: { loadingCount } }) => {
  if (loadingCount <= 0) return null;
  return <LoadingOverlayComponent />;
};

const mapStateToProps = state => ({
  ui: state.ui
});

export default connect(mapStateToProps)(LoadingOverlay);
