import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AdminRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, isAdmin, user },
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (!loading && (!isAuthenticated || !isAdmin)) {
        return <Redirect to='/login' />;
      } else if (!loading && user) {
        return <Component {...props} />;
      }
      return null;
    }}
  />
);

AdminRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AdminRoute);
