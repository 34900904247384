import uuid from 'uuid';
import { CREATE_ALERT, REMOVE_ALERT, REMOVE_ALL_ALERTS } from './types';

export const createAlert = (msg, alertType = 'danger', msgArgs) => dispatch => {
  const id = uuid.v4();
  dispatch({
    type: CREATE_ALERT,
    payload: { msg, alertType, id, msgArgs }
  });
};

export const removeAlert = alertId => dispatch => {
  dispatch({
    type: REMOVE_ALERT,
    payload: alertId
  });
};

export const removeAllAlerts = () => dispatch => {
  dispatch({
    type: REMOVE_ALL_ALERTS
  });
};
