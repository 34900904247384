import { ADD_UI_LOADING, REMOVE_UI_LOADING } from './types';

export const addUILoading = () => dispatch => {
  dispatch({
    type: ADD_UI_LOADING
  });
};

export const removeUILoading = alertId => dispatch => {
  dispatch({
    type: REMOVE_UI_LOADING
  });
};