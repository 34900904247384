import {
  CHANGE_LANG
} from '../actions/types';
import { localStorageKeys, defaultLang } from '../utils/const';
import translate from '../lang';

const { LANG: KEY_LANG } = localStorageKeys;

const initialState = localStorage.getItem(KEY_LANG) || defaultLang;

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_LANG:
      localStorage.setItem(KEY_LANG, payload);
      state = payload;
      break;
    default:
  }
  
  document.title = translate('PRODUCT_NAME', state);
  return state;
};