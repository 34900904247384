import {
  ORDER_CREATE_SUCCESS,
  ORDER_EDIT_SUCCESS,
  START_LOADING_ORDERS_PAGE,
  ORDERS_PAGE_LOAD_SUCCESS,
  ORDERS_PAGE_LOAD_FAILED,
  TOGGLE_ORDER_FILTER_COLLAPSE,
  SET_ORDER_FILTER_COLLAPSE_ANIM_STATE,
  ORDERS_SET_FILTER,
  ORDERS_ADD_FILTER,
  ORDERS_REMOVE_FILTER,
  ORDERS_ADD_FILTER_DATE_FROM,
  ORDERS_ADD_FILTER_DATE_TO,
  ORDERS_REMOVE_FILTER_DATE_FROM,
  ORDERS_REMOVE_FILTER_DATE_TO,
  START_EDITING_ORDER,
  FINISH_EDITING_ORDER,
  ORDER_EDIT_FIELD,
  ORDER_DELETE_SUCCESS,
  ORDER_CANCEL_SUCCESS,
  ORDER_SUBMIT_SUCCESS,
  ORDER_CONFIRM_SUCCESS,
  ORDERS_COMPLETE_SUCCESS,
  REFRESH_ORDERS,
  ORDERS_SELECT_ALL,
  ORDERS_DESELECT_ALL,
  ORDERS_SELECT,
  ORDERS_DESELECT,
  ORDERS_FORM_DOWNLOADED,
  MISA_ORDERS_FORM_DOWNLOADED,
  ORDER_ATTACHMENT_START_UPLOADING,
  ORDER_ATTACHMENT_UPLOAD_SUCCESS,
  ORDER_ATTACHMENT_UPLOAD_FAILED,
  ORDER_ATTACHMENT_REMOVE_SUCCESS,
  ORDERS_EMAILS_SENT,
} from "../actions/types";
import orderEnum from "../enum/order";

const initialState = {
  filter: {},
  filterDateFrom: {},
  filterDateTo: {},
  filterCollapsed: false,
  filterCollapseAnimating: false,
  reverse: true,
  loadingOrders: false,
  totals: {},
  orders: [],
  nextId: null,
  pageEnd: false,
  editingRow: null,
  editedFields: {},
  selectedAll: false,
  selectedIds: [],
  uploadingAttachments: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  const clearData = {
    totals: {},
    orders: [],
    nextId: null,
    pageEnd: false,
    selectedAll: false,
    selectedIds: [],
  };
  switch (type) {
    case REFRESH_ORDERS:
    case ORDERS_COMPLETE_SUCCESS:
      return {
        ...state,
        ...clearData,
      };
    case ORDER_CREATE_SUCCESS:
      return {
        ...state,
        editingRow: payload,
        editedFields: {},
        orders: [payload, ...state.orders],
        totals: {
          ...state.totals,
          draft: (state.totals.draft || 0) + 1,
        },
      };
    case ORDER_DELETE_SUCCESS: {
      if (state.totals.draft) {
        return {
          ...state,
          orders: state.orders.filter(
            (val) =>
              !(val.status === orderEnum.status.draft && val._id === payload)
          ),
          totals: {
            ...state.totals,
            draft: state.totals.draft - 1,
          },
        };
      }
      return {
        ...state,
        ...clearData,
      };
    }
    case START_LOADING_ORDERS_PAGE:
      return {
        ...state,
        loadingOrders: true,
      };
    case ORDERS_PAGE_LOAD_SUCCESS:
      return {
        ...state,
        loadingOrders: false,
        totals: {
          ...state.totals,
          ...payload.totals,
        },
        orders: [...state.orders, ...payload.data],
        nextId: payload.nextId,
        pageEnd: !payload.nextId,
      };
    case ORDERS_PAGE_LOAD_FAILED:
      return {
        ...state,
        loadingOrders: false,
      };
    case TOGGLE_ORDER_FILTER_COLLAPSE:
      return {
        ...state,
        filterCollapsed: !state.filterCollapsed,
      };
    case SET_ORDER_FILTER_COLLAPSE_ANIM_STATE:
      return {
        ...state,
        filterCollapseAnimating: payload,
      };
    case ORDERS_SET_FILTER:
      return {
        ...state,
        ...clearData,
        filterDateFrom: {},
        filterDateTo: {},
        filter: payload,
      };
    case ORDERS_ADD_FILTER:
      return {
        ...state,
        ...clearData,
        filter: { ...state.filter, ...payload },
      };
    case ORDERS_REMOVE_FILTER: {
      const { [payload]: _, ...newFilter } = state.filter;
      return {
        ...state,
        ...clearData,
        filter: newFilter,
      };
    }
    case ORDERS_ADD_FILTER_DATE_FROM:
      return {
        ...state,
        ...clearData,
        filterDateFrom: {
          ...state.filterDateFrom,
          ...payload.filterDate,
        },
        filter: {
          ...state.filter,
          ...Object.fromEntries(
            Object.entries(payload.filterISOString).map(([key, val]) => [
              key,
              { ...state.filter[key], $gte: val },
            ])
          ),
        },
      };
    case ORDERS_ADD_FILTER_DATE_TO:
      return {
        ...state,
        ...clearData,
        filterDateTo: {
          ...state.filterDateTo,
          ...payload.filterDate,
        },
        filter: {
          ...state.filter,
          ...Object.fromEntries(
            Object.entries(payload.filterISOString).map(([key, val]) => [
              key,
              {
                ...state.filter[key],
                $lt: val,
              },
            ])
          ),
        },
      };
    case ORDERS_REMOVE_FILTER_DATE_FROM: {
      // payload: [field_name, field_name2]
      let newFilter = {
        ...state.filter,
      };
      let removed = false;
      for (let fieldName of payload) {
        const { [fieldName]: fieldFilter, ...restNewFilter } = newFilter;
        const { $gte, ...restFieldFilter } = fieldFilter || {};
        if (!fieldFilter || !$gte) {
          continue;
        }
        removed = true;
        newFilter = {
          ...restNewFilter,
          ...(Object.keys(restFieldFilter).length > 0
            ? { [fieldName]: restFieldFilter }
            : {}),
        };
      }
      if (!removed) return state;
      return {
        ...state,
        ...clearData,
        filterDateFrom: Object.fromEntries(
          Object.entries(state.filterDateFrom).filter(
            ([key, val]) => !payload.includes(key)
          )
        ),
        filter: newFilter,
      };
    }
    case ORDERS_REMOVE_FILTER_DATE_TO: {
      let newFilter = {
        ...state.filter,
      };
      let removed = false;
      for (let fieldName of payload) {
        const { [fieldName]: fieldFilter, ...restNewFilter } = newFilter;
        const { $lt, ...restFieldFilter } = fieldFilter || {};
        if (!fieldFilter || !$lt) {
          continue;
        }
        removed = true;
        newFilter = {
          ...restNewFilter,
          ...(Object.keys(restFieldFilter).length > 0
            ? { [fieldName]: restFieldFilter }
            : {}),
        };
      }
      if (!removed) return state;
      return {
        ...state,
        ...clearData,
        filterDateTo: Object.fromEntries(
          Object.entries(state.filterDateTo).filter(
            ([key, val]) => !payload.includes(key)
          )
        ),
        filter: newFilter,
      };
    }
    case START_EDITING_ORDER:
      return {
        ...state,
        editingRow: payload,
        editedFields: {},
      };
    case FINISH_EDITING_ORDER:
      return {
        ...state,
        editingRow: null,
        editedFields: {},
      };
    case ORDER_EDIT_FIELD:
      return {
        ...state,
        editedFields: {
          ...state.editedFields,
          ...payload,
        },
        editingRow: {
          ...state.editingRow,
          ...Object.fromEntries(
            Object.entries(payload).map(([key, val]) => {
              if (
                typeof state.editingRow[key] === "object" &&
                state.editingRow[key]
              )
                // null is also an object so make sure not null
                return [
                  key,
                  {
                    ...state.editingRow[key],
                    _id: val,
                  },
                ];
              return [key, val];
            })
          ),
        },
      };
    case ORDER_EDIT_SUCCESS:
      return {
        ...state,
        editedFields: {},
        editingRow:
          state.editingRow && state.editingRow._id === payload._id
            ? payload
            : state.editingRow,
        orders: state.orders.map((v) => {
          if (v._id === payload._id) {
            return payload;
          }
          return v;
        }),
      };
    case ORDER_SUBMIT_SUCCESS: {
      if (state.totals.draft) {
        return {
          ...state,
          orders: [
            ...state.orders.filter(
              (val) =>
                val.status === orderEnum.status.draft &&
                val._id !== payload.draftId
            ),
            payload.order,
            ...state.orders.filter(
              (val) => val.status !== orderEnum.status.draft
            ),
          ],
          totals: {
            ...state.totals,
            draft: state.totals.draft - 1,
            submitted: (state.totals.submitted || 0) + 1,
          },
        };
      }
      return {
        ...state,
        ...clearData,
      };
    }
    case ORDER_CONFIRM_SUCCESS:
    case ORDER_CANCEL_SUCCESS: {
      // from submitted to confirmed / cancelled
      if (state.totals.submitted) {
        const status =
          type === ORDER_CONFIRM_SUCCESS
            ? orderEnum.status.confirmed
            : orderEnum.status.cancelled;
        // if set filter not the updated status, filter away
        if (state.filter.status && state.filter.status !== status) {
          return {
            ...state,
            orders: state.orders.filter((v) => {
              return v._id !== payload._id;
            }),
            totals: {
              ...state.totals,
              submitted: state.totals.submitted - 1,
            },
          };
        }
        // else update the order
        return {
          ...state,
          orders: state.orders.map((v) => {
            if (v._id === payload._id) return payload;
            return v;
          }),
          totals: {
            ...state.totals,
            submitted: state.totals.submitted - 1,
            [status]: (state.totals[status] || 0) + 1,
          },
        };
      }
      return {
        ...state,
        ...clearData,
      };
    }
    case ORDERS_SELECT_ALL:
      return {
        ...state,
        selectedAll: true,
        selectedIds: state.orders.map((val) => val._id),
      };
    case ORDERS_DESELECT_ALL:
      return {
        ...state,
        selectedAll: false,
        selectedIds: [],
      };
    case ORDERS_SELECT:
      return {
        ...state,
        selectedAll: state.selectedIds.length + 1 === state.orders.length,
        selectedIds: [...state.selectedIds, payload],
      };
    case ORDERS_DESELECT:
      return {
        ...state,
        selectedAll: false,
        selectedIds: state.selectedIds.filter((val) => val !== payload),
      };
    case ORDERS_FORM_DOWNLOADED:
      return {
        ...state,
        orders: state.orders.map((val) => {
          if (payload.includes(val._id)) {
            return {
              ...val,
              formDownloaded: true,
            };
          }
          return val;
        }),
      };
    case MISA_ORDERS_FORM_DOWNLOADED:
      return {
        ...state,
        orders: state.orders.map((val) => {
          if (payload.includes(val._id)) {
            return {
              ...val,
              formDownloaded: true,
            };
          }
          return val;
        }),
      };
    case ORDERS_EMAILS_SENT:
      return {
        ...state,
        orders: state.orders.map((val) => {
          if (payload.includes(val._id)) {
            return {
              ...val,
              emailsSent: true,
            };
          }
          return val;
        }),
      };
    case ORDER_ATTACHMENT_START_UPLOADING:
      return {
        ...state,
        uploadingAttachments: [...state.uploadingAttachments, { ...payload }],
      };
    case ORDER_ATTACHMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadingAttachments: state.uploadingAttachments.filter(
          (v) => v.uuid !== payload.uuid
        ),
        orders: state.orders.map((v) => {
          if (v._id === payload.order._id) {
            return payload.order;
          }
          return v;
        }),
      };
    case ORDER_ATTACHMENT_UPLOAD_FAILED:
      return {
        ...state,
        uploadingAttachments: state.uploadingAttachments.filter(
          (v) => v.uuid !== payload.uuid
        ),
      };
    case ORDER_ATTACHMENT_REMOVE_SUCCESS:
      return {
        ...state,
        orders: state.orders.map((v) => {
          if (v._id === payload._id) {
            return payload;
          }
          return v;
        }),
      };
    default:
      return state;
  }
};
