import React from 'react';
import { Jumbotron, Container } from 'reactstrap';
import Translate from '../lang/Translate';

const NotFound = () => {
  return (
    <div>
      <Jumbotron fluid>
        <Container fluid>
          <h1 className="display-3"><i className='fas fa-exclamation-triangle' />{' '}<Translate msg='404_TITLE' /></h1>
          <p className="lead"><Translate msg='404_DESC' /></p>
        </Container>
      </Jumbotron>
    </div>
  );
};

export default NotFound;