// Alert
export const CREATE_ALERT = "CREATE_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REMOVE_ALL_ALERTS = "REMOVE_ALL_ALERTS";

// UI
export const ADD_UI_LOADING = "ADD_UI_LOADING";
export const REMOVE_UI_LOADING = "REMOVE_UI_LOADING";

// Auth
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const AUTH_UPDATE_SUCCESS = "AUTH_UPDATE_SUCCESS";
export const INIT_FORGOT_PW_PAGE = "INIT_FORGOT_PW_PAGE";
export const FORGOT_PW_REQUEST_SENT = "FORGOT_PW_REQUEST_SENT";
export const START_VERIFY_RESET_PW_TOKEN = "START_VERIFY_RESET_PW_TOKEN";
export const VERIFY_RESET_PW_TOKEN_SUCCESS = "VERIFY_RESET_PW_TOKEN_SUCCESS";
export const VERIFY_RESET_PW_TOKEN_FAILED = "VERIFY_RESET_PW_TOKEN_FAILED";
export const RESET_PW_SUCCESS = "RESET_PW_SUCCESS";

// Language / Localization
export const CHANGE_LANG = "CHANGE_LANG";

// Manage Users
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAILED = "USER_GET_FAILED";
export const START_LOADING_USER = "START_LOADING_USER";
export const START_LOADING_USERS_PAGE = "START_LOADING_USERS_PAGE";
export const USERS_PAGE_LOAD_SUCCESS = "USERS_PAGE_LOAD_SUCCESS";
export const USERS_PAGE_LOAD_FAILED = "USERS_PAGE_LOAD_FAILED";
export const USERS_UPDATE_SORT = "USERS_UPDATE_SORT";
export const USERS_SET_FILTER = "USERS_SET_FILTER";
export const REFRESH_USERS = "REFRESH_USERS";

// External Users
export const EXTERNAL_USERS_LOADED = "EXTERNAL_USERS_LOADED";
export const EXTERNAL_USERS_LOAD_SKIPPED = "EXTERNAL_USERS_LOAD_SKIPPED";

// KS Dept
export const KS_DEPTS_LOADED = "KS_DEPTS_LOADED";

// Item
export const ITEM_HIERARCHY_LOADED = "ITEM_HIERARCHY_LOADED";
export const START_LOADING_ITEM_TYPES = "START_LOADING_ITEM_TYPES";
export const ITEM_TYPES_LOAD_SUCCESS = "ITEM_TYPES_LOAD_SUCCESS";
export const ITEM_TYPES_LOAD_FAILED = "ITEM_TYPES_LOAD_FAILED";
export const START_LOADING_ITEMS = "START_LOADING_ITEMS";
export const ITEMS_LOAD_SUCCESS = "ITEMS_LOAD_SUCCESS";
export const ITEMS_LOAD_FAILED = "ITEMS_LOAD_FAILED";
export const ITEM_TYPE_UPDATE_SUCCESS = "ITEM_TYPE_UPDATE_SUCCESS";
export const START_EDITING_ITEM_TYPE = "START_EDITING_ITEM_TYPE";
export const FINISH_EDITING_ITEM_TYPE = "FINISH_EDITING_ITEM_TYPE";
export const EDIT_ITEM_TYPE_FIELD = "EDIT_ITEM_TYPE_FIELD";
export const ITEM_UPDATE_SUCCESS = "ITEM_UPDATE_SUCCESS";
export const START_EDITING_ITEM = "START_EDITING_ITEM";
export const FINISH_EDITING_ITEM = "FINISH_EDITING_ITEM";
export const EDIT_ITEM_FIELD = "EDIT_ITEM_FIELD";
export const ITEM_CREATE_SUCCESS = "ITEM_CREATE_SUCCESS";

// Supplier
export const START_LOADING_SUPPLIERS = "START_LOADING_SUPPLIERS";
export const SUPPLIERS_LOAD_SUCCESS = "SUPPLIERS_LOAD_SUCCESS";
export const SUPPLIERS_LOAD_FAILED = "SUPPLIERS_LOAD_FAILED";
export const SUPPLIERS_LOAD_SKIPPED = "SUPPLIERS_LOAD_SKIPPED";
export const SUPPLIER_CREATE_SUCCESS = "SUPPLIER_CREATE_SUCCESS";
export const SUPPLIER_UPDATE_SUCCESS = "SUPPLIER_UPDATE_SUCCESS";

// Order
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS"; // draft
export const ORDER_EDIT_SUCCESS = "ORDER_EDIT_SUCCESS"; // draft or real
export const START_LOADING_ORDERS_PAGE = "START_LOADING_ORDERS_PAGE";
export const ORDERS_PAGE_LOAD_SUCCESS = "ORDERS_PAGE_LOAD_SUCCESS";
export const ORDERS_PAGE_LOAD_FAILED = "ORDERS_PAGE_LOAD_FAILED";
export const TOGGLE_ORDER_FILTER_COLLAPSE = "TOGGLE_ORDER_FILTER_COLLAPSE";
export const SET_ORDER_FILTER_COLLAPSE_ANIM_STATE =
  "SET_ORDER_FILTER_COLLAPSE_ANIM_STATE";
export const ORDERS_SET_FILTER = "ORDERS_SET_FILTER";
export const ORDERS_ADD_FILTER = "ORDERS_ADD_FILTER";
export const ORDERS_REMOVE_FILTER = "ORDERS_REMOVE_FILTER";
export const ORDERS_ADD_FILTER_DATE_FROM = "ORDERS_ADD_FILTER_DATE_FROM";
export const ORDERS_ADD_FILTER_DATE_TO = "ORDERS_ADD_FILTER_DATE_TO";
export const ORDERS_REMOVE_FILTER_DATE_FROM = "ORDERS_REMOVE_FILTER_DATE_FROM";
export const ORDERS_REMOVE_FILTER_DATE_TO = "ORDERS_REMOVE_FILTER_DATE_TO";
export const START_EDITING_ORDER = "START_EDITING_ORDER"; // draft or real
export const FINISH_EDITING_ORDER = "FINISH_EDITING_ORDER"; // draft or real
export const ORDER_EDIT_FIELD = "ORDER_EDIT_FIELD"; // draft or real
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS"; // draft
export const ORDER_CANCEL_SUCCESS = "ORDER_CANCEL_SUCCESS"; // real
export const ORDER_SUBMIT_SUCCESS = "ORDER_SUBMIT_SUCCESS"; // real
export const ORDER_CONFIRM_SUCCESS = "ORDER_CONFIRM_SUCCESS"; // real
export const ORDERS_COMPLETE_SUCCESS = "ORDERS_COMPLETE_SUCCESS"; // real
export const REFRESH_ORDERS = "REFRESH_ORDERS";
export const ORDERS_SELECT_ALL = "ORDERS_SELECT_ALL";
export const ORDERS_DESELECT_ALL = "ORDERS_DESELECT_ALL";
export const ORDERS_SELECT = "ORDERS_SELECT";
export const ORDERS_DESELECT = "ORDERS_DESELECT";
export const ORDERS_FORM_DOWNLOADED = "ORDERS_FORM_DOWNLOADED";
export const MISA_ORDERS_FORM_DOWNLOADED = "MISA_ORDERS_FORM_DOWNLOADED";
export const ORDER_ATTACHMENT_START_UPLOADING =
  "ORDER_ATTACHMENT_START_UPLOADING";
export const ORDER_ATTACHMENT_UPLOAD_SUCCESS =
  "ORDER_ATTACHMENT_UPLOAD_SUCCESS";
export const ORDER_ATTACHMENT_UPLOAD_FAILED = "ORDER_ATTACHMENT_UPLOAD_FAILED";
export const ORDER_ATTACHMENT_REMOVE_SUCCESS =
  "ORDER_ATTACHMENT_REMOVE_SUCCESS";
export const ORDERS_EMAILS_SENT = "ORDERS_EMAILS_SENT";

// Admin Settings
export const START_LOADING_ADMIN_SETTINGS = "START_LOADING_ADMIN_SETTINGS";
export const ADMIN_SETTINGS_LOAD_SUCCESS = "ADMIN_SETTINGS_LOAD_SUCCESS";
export const ADMIN_SETTINGS_LOAD_FAILED = "ADMIN_SETTINGS_LOAD_FAILED";
export const ADMIN_SETTINGS_UPDATE_SUCCESS = "ADMIN_SETTINGS_UPDATE_SUCCESS";

// ProjectMappingId
export const START_LOADING_PROJECTIDMAPPINGS =
  "START_LOADING_PROJECTIDMAPPINGS";
export const PROJECTIDMAPPINGS_LOAD_SUCCESS = "PROJECTIDMAPPINGS_LOAD_SUCCESS";
export const PROJECTIDMAPPINGS_LOAD_FAILED = "PROJECTIDMAPPINGS_LOAD_FAILED";
export const PROJECTIDMAPPINGS_LOAD_SKIPPED = "PROJECTIDMAPPINGS_LOAD_SKIPPED";
export const PROJECTIDMAPPING_CREATE_SUCCESS =
  "PROJECTIDMAPPINGS_CREATE_SUCCESS";
export const PROJECTIDMAPPING_UPDATE_SUCCESS =
  "PROJECTIDMAPPINGS_UPDATE_SUCCESS";
export const START_LOADING_PROJECTID_MIXIN_OPTIONS = "START_LOADING_PROJECTID_MIXIN_OPTIONS";
export const PROJECTID_MIXIN_OPTIONS_LOAD_SUCCESS = "PROJECTID_MIXIN_OPTIONS_LOAD_SUCCESS";
export const PROJECTID_MIXIN_OPTIONS_LOAD_FAILED = "PROJECTID_MIXIN_OPTIONS_LOAD_FAILED";
