import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { changeLang } from "../../actions/lang";
import { downloadUserManual } from "../../actions/userManual";
import { supportedLang } from "../../utils/const";
import getLangName from "../../utils/getLangName";
import Translate from "../lang/Translate";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const NavBar = ({
  auth: { isAuthenticated, isAdmin, loading, user },
  lang,
  logout,
  changeLang,
  downloadUserManual,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const userProfileDropdown = (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        {!loading && user && (
          <Fragment>
            {isAdmin ? (
              <i className="fas fa-user-circle text-danger" />
            ) : (
              <i className="fas fa-user-circle" />
            )}{" "}
            {user.name.length > 12
              ? `${user.name.substring(0, 10)}...`
              : user.name}
          </Fragment>
        )}
      </DropdownToggle>
      <DropdownMenu right>
        <Link className="text-decoration-none" to="/me">
          <DropdownItem className="p-2 px-3">
            <Translate msg="USER_INFO" />
          </DropdownItem>
        </Link>
        <Link className="text-decoration-none" to="/change_pw">
          <DropdownItem className="p-2 px-3">
            <Translate msg="CHANGE_PW" />
          </DropdownItem>
        </Link>
        <DropdownItem className="p-2 px-3" onClick={logout}>
          <i className="fas fa-sign-out-alt" /> <Translate msg="LOGOUT" />
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
  const langDropDown = (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        <i className="fas fa-globe" /> {getLangName(lang)}
      </DropdownToggle>
      <DropdownMenu right>
        {Object.entries(supportedLang).map(([key, val]) => (
          <DropdownItem
            className="p-2 px-3"
            key={key}
            onClick={() => changeLang(val)}
            active={lang === val}
          >
            {getLangName(val)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const adminLinks = (
    <Fragment>
      <Nav className="mr-auto" navbar>
        <NavItem>
          <Link className="nav-link" to="/users">
            <Translate msg="MANAGE_USERS" />
          </Link>
        </NavItem>
        <NavItem>
          <Link className="nav-link" to="/orders">
            <Translate msg="MANAGE_ORDERS" />
          </Link>
        </NavItem>
        <NavItem>
          <Link className="nav-link" to="/orders/download_excel">
            <Translate msg="HANDLE_ORDERS" />
          </Link>
        </NavItem>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            <Translate msg="MANAGE_OTHERS" />
          </DropdownToggle>
          <DropdownMenu>
            <Link className="text-decoration-none" to="/items">
              <DropdownItem className="p-2 px-3">
                <Translate msg="MANAGE_ITEMS" />
              </DropdownItem>
            </Link>
            <Link className="text-decoration-none" to="/suppliers">
              <DropdownItem className="p-2 px-3">
                <Translate msg="MANAGE_SUPPLIERS" />
              </DropdownItem>
            </Link>
            <Link className="text-decoration-none" to="/projectIdMapping">
              <DropdownItem className="p-2 px-3">
                <Translate msg="MANAGE_PROJECTID" />
              </DropdownItem>
            </Link>
            <Link className="text-decoration-none" to="/admin_settings">
              <DropdownItem className="p-2 px-3">
                <Translate msg="UPDATE_ADMIN_SETTINGS" />
              </DropdownItem>
            </Link>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            <Translate msg="USER_MANUAL" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              className="p-2 px-3"
              onClick={() => {
                downloadUserManual("admin");
              }}
            >
              <Translate msg="USER_MANUAL_ADMIN" />
            </DropdownItem>
            <DropdownItem
              className="p-2 px-3"
              onClick={() => {
                downloadUserManual("ks_sc");
              }}
            >
              <Translate msg="USER_MANUAL_KS_SC" />
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
      <Nav navbar>
        {langDropDown}
        {userProfileDropdown}
      </Nav>
    </Fragment>
  );
  const externalLinks = (
    <Fragment>
      <Nav className="mr-auto" navbar>
        <NavItem>
          <Link className="nav-link" to="/orders">
            <Translate msg="MANAGE_ORDERS" />
          </Link>
        </NavItem>
        <NavItem>
          <Link
            className="nav-link"
            to="/"
            onClick={(e) => {
              e.preventDefault();
              downloadUserManual("ks_sc");
            }}
          >
            <Translate msg="USER_MANUAL" />
          </Link>
        </NavItem>
      </Nav>
      <Nav navbar>
        {langDropDown}
        {userProfileDropdown}
      </Nav>
    </Fragment>
  );

  const guestLinks = (
    <Nav className="ml-auto" navbar>
      {langDropDown}
      <NavItem>
        <Link className="nav-link" to="/login">
          <Translate msg="LOGIN" />
        </Link>
      </NavItem>
    </Nav>
  );

  return (
    <div id="nav-bar-container">
      <Navbar light expand="md">
        <NavbarBrand href="/">
          <i className="fas fa-store" /> <Translate msg="PRODUCT_NAME" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          {!loading && (
            <Fragment>
              {isAuthenticated
                ? isAdmin
                  ? adminLinks
                  : externalLinks
                : guestLinks}
            </Fragment>
          )}
        </Collapse>
      </Navbar>
    </div>
  );
};

NavBar.propTypes = {
  logout: PropTypes.func.isRequired,
  changeLang: PropTypes.func.isRequired,
  downloadUserManual: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  lang: state.lang,
});

export default connect(mapStateToProps, {
  logout,
  changeLang,
  downloadUserManual,
})(NavBar);
