import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../routing/PrivateRoute";
import AdminRoute from "../routing/AdminRoute";
import AlertContainer from "../layout/AlertContainer";

import Login from "../auth/LoginLazy";
import ForgotPassword from "../auth/ForgotPasswordLazy";
import ResetPassword from "../auth/ResetPasswordLazy";
import Dashboard from "../dashboard/DashboardLazy";
import UserInfo from "../user/UserInfoLazy";
import ChangeSelfPassword from "../user/ChangeSelfPasswordLazy";
import ManageUsers from "../user/ManageUsersLazy";
import CreateOrUpdateUser from "../user/CreateOrUpdateUserLazy";
import ResetUserPassword from "../user/ResetUserPasswordLazy";
import ManageOrders from "../order/ManageOrdersLazy";
import DownloadExcel from "../order/DownloadExcelLazy";
import ManageItemHierarchy from "../item/ManageItemHierarchyLazy";
import ManageSuppliers from "../supplier/ManageSuppliersLazy";
import ManageProjectIdsMapping from "../projectIdMapping/ManageProjectIdMappingsLazy";
import UpdateAdminSettings from "../adminSettings/UpdateAdminSettingsLazy";

import NotFound from "../layout/NotFound";

const Routes = () => {
  return (
    <section className="container-fluid">
      <AlertContainer />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot_pw" component={ForgotPassword} />
        <Route path="/reset_pw/:token" component={ResetPassword} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/me" component={UserInfo} />
        <PrivateRoute exact path="/change_pw" component={ChangeSelfPassword} />
        <AdminRoute exact path="/users" component={ManageUsers} />
        <AdminRoute exact path="/users/create" component={CreateOrUpdateUser} />
        <AdminRoute
          path="/users/update/:updateUserId"
          component={CreateOrUpdateUser}
        />
        <AdminRoute
          path="/users/reset_pw/:updateUserId"
          component={ResetUserPassword}
        />
        <PrivateRoute exact path="/orders" component={ManageOrders} />
        <AdminRoute
          exact
          path="/orders/download_excel"
          component={DownloadExcel}
        />
        <AdminRoute exact path="/items" component={ManageItemHierarchy} />
        <AdminRoute exact path="/suppliers" component={ManageSuppliers} />
        <AdminRoute
          exact
          path="/projectIdMapping"
          component={ManageProjectIdsMapping}
        />
        <AdminRoute
          exact
          path="/admin_settings"
          component={UpdateAdminSettings}
        />
        <Route component={NotFound} />
      </Switch>
    </section>
  );
};

export default Routes;
