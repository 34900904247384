import { supportedLang, defaultLang } from '../utils/const';

const langConf = {};
Object.entries(supportedLang).forEach(([key, val]) => {
  try {
    // console.log(`Configuring language ${val}...`);
    langConf[val] = require(`./def/${val}.json`);
  } catch (err) {
    console.error(`Error reading ./def/${val}.json`);
  }
});

export default (key, lang = defaultLang) => {
  if (!langConf[lang]) {
    // lang not defined, use default lang
    lang = defaultLang;
  }
  const langDef = langConf[lang];
  if (langDef[key] === undefined) {
    console.error(`${key} is not defined for ${lang}`);
    return key;
  }
  return langDef[key];
};
