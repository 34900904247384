import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './utils/history';
import LoadingOverlay from './components/layout/LoadingOverlay';
import NavBar from './components/layout/NavBar';
import Landing from './components/layout/Landing';
import Routes from './components/routing/Routes';
import axios from 'axios';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import { localStorageKeys } from './utils/const';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

axios.defaults.headers.post['Content-Type'] = 'application/json';
const { AUTH_TOKEN: KEY_AUTH_TOKEN } = localStorageKeys;
const storedAuthToken = localStorage.getItem(KEY_AUTH_TOKEN);
if (storedAuthToken) {
  setAuthToken(storedAuthToken);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router history={history}>
        <div style={{ minHeight: window.innerHeight }}>
          <LoadingOverlay />
          <NavBar />
          <Switch>
            <Route exact path='/' component={Landing} />
            <Route component={Routes} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
