import { createAlert } from '../actions/alert';
import { AUTH_ERROR } from '../actions/types';
import createConfirmPopup from './createConfirmPopup';

const defaultOptions = {
  alert: true,
  handleAuthDenied: true,
  isFatal: false
};

export default (err, opts = {}) => async dispatch => {
  const options = {
    ...defaultOptions,
    ...opts
  };
  if (options.alert) {
    if (err.response) {
      const errors = err.response.data.errors;
      const error = err.response.data.error;

      if (errors) {
        errors.forEach(e => dispatch(createAlert(e.message, 'danger')));
      } else if (error) {
        dispatch(createAlert(error.message, 'danger'));
      }
    } else {
      dispatch(createAlert('UNKNOWN_ERROR', 'danger'));
    }
  }
  let authDeniedHandled = false;
  if (options.handleAuthDenied) {
    if (
      err.response &&
      err.response.status === 401 &&
      err.response.data.error &&
      (err.response.data.error.message === 'RES_ERR_AUTH_DENIED' ||
        err.response.data.error.message === 'RES_ERR_USER_SUSPENDED')
    ) {
      authDeniedHandled = true;
      dispatch({
        type: AUTH_ERROR
      });
    }
  }
  if (!authDeniedHandled && options.isFatal) {
    createConfirmPopup(
      'SERVER_FATAL_ERROR',
      () => {
        window.location.replace('/');
      },
      {
        hasCancelBtn: false
      }
    );
  }
};
