import translate from '../lang';
import store from '../store';
import { vsprintf } from 'sprintf-js';

const defaultOptions = {
  hasCancelBtn: true,
  msgArgs: null
};
export default (msg, cbConfirm, options = {}) => {
  const mergedOptions = {
    ...defaultOptions,
    ...options
  };
  const outputMsg = mergedOptions.msgArgs
    ? vsprintf(translate(msg, store.getState().lang), mergedOptions.msgArgs)
    : translate(msg, store.getState().lang);
  if (mergedOptions.hasCancelBtn) {
    if (window.confirm(outputMsg)) {
      if (cbConfirm) {
        cbConfirm();
      }
    }
  } else {
    alert(outputMsg);
    if (cbConfirm) {
      cbConfirm();
    }
  }
};
