import { combineReducers } from "redux";
import ui from "./ui";
import alert from "./alert";
import auth from "./auth";
import lang from "./lang";
import manageUsers from "./manageUsers";
import ksDept from "./ksDept";
import item from "./item";
import supplier from "./supplier";
import order from "./order";
import projectIdMapping from "./projectIdMapping";
import adminSettings from "./adminSettings";

export default combineReducers({
  ui,
  alert,
  auth,
  lang,
  manageUsers,
  ksDept,
  item,
  supplier,
  order,
  projectIdMapping,
  adminSettings,
});
