export const localStorageKeys = {
  AUTH_TOKEN: 'access_token',
  LOGIN_NAME: 'login_name',
  LANG: 'lang'
};

export const supportedLang = {
  ZH_HK: 'zh_hk',
  EN: 'en'
};
export const defaultLang = supportedLang.ZH_HK;

export const timezone = 'Asia/Hong_Kong';

export const recaptchaSiteKey = '6Ld7V-IUAAAAAGo9lBvpH_bMBfz_jIpJ1vSt_Irv';
