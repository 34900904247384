import {
  ITEM_HIERARCHY_LOADED,
  START_LOADING_ITEM_TYPES,
  ITEM_TYPES_LOAD_SUCCESS,
  ITEM_TYPES_LOAD_FAILED,
  START_LOADING_ITEMS,
  ITEMS_LOAD_SUCCESS,
  ITEMS_LOAD_FAILED,
  ITEM_TYPE_UPDATE_SUCCESS,
  START_EDITING_ITEM_TYPE,
  FINISH_EDITING_ITEM_TYPE,
  EDIT_ITEM_TYPE_FIELD,
  ITEM_UPDATE_SUCCESS,
  START_EDITING_ITEM,
  FINISH_EDITING_ITEM,
  EDIT_ITEM_FIELD,
  ITEM_CREATE_SUCCESS
} from '../actions/types';

const initialState = {
  loadingItemHierarchy: true,
  itemHierarchy: [],
  loadingItemTypes: false,
  itemTypes: [],
  loadingItems: false,
  items: [],
  editingItemTypeRow: null,
  editedItemTypeFields: {},
  editingItemRow: null,
  editedItemFields: {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ITEM_HIERARCHY_LOADED:
      return {
        ...state,
        loadingItemHierarchy: false,
        itemHierarchy: [...payload]
      };
    case START_LOADING_ITEM_TYPES:
      return {
        ...state,
        loadingItemTypes: true
      };
    case ITEM_TYPES_LOAD_SUCCESS:
      return {
        ...state,
        loadingItemTypes: false,
        itemTypes: [...payload]
      };
    case ITEM_TYPES_LOAD_FAILED:
      return {
        ...state,
        loadingItemTypes: false
      };
    case START_LOADING_ITEMS:
      return {
        ...state,
        loadingItems: true
      };
    case ITEMS_LOAD_SUCCESS:
      return {
        ...state,
        loadingItems: false,
        items: [...payload]
      };
    case ITEMS_LOAD_FAILED:
      return {
        ...state,
        loadingItems: false
      };
    case ITEM_TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        itemTypes: state.itemTypes.map(v => {
          if (v._id === payload._id) {
            return payload;
          }
          return v;
        }),
        editedItemTypeFields: {},
        editingItemTypeRow:
          state.editingItemTypeRow && state.editingItemTypeRow._id === payload._id
            ? payload
            : state.editingItemTypeRow
      };
    case ITEM_UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map(v => {
          if (v._id === payload._id) {
            return payload;
          }
          return v;
        }),
        editedItemFields: {},
        editingItemRow:
          state.editingItemRow && state.editingItemRow._id === payload._id
            ? payload
            : state.editingItemRow
      };
    case START_EDITING_ITEM_TYPE:
      return {
        ...state,
        editingItemTypeRow: payload,
        editedItemTypeFields: {}
      };
    case START_EDITING_ITEM:
      return {
        ...state,
        editingItemRow: payload,
        editedItemFields: {}
      };
    case FINISH_EDITING_ITEM_TYPE:
      return {
        ...state,
        editingItemTypeRow: null,
        editedItemTypeFields: {}
      };
    case FINISH_EDITING_ITEM:
      return {
        ...state,
        editingItemRow: null,
        editedItemFields: {}
      };
    case EDIT_ITEM_TYPE_FIELD:
      return {
        ...state,
        editedItemTypeFields: {
          ...state.editedItemTypeFields,
          ...payload
        },
        editingItemTypeRow: {
          ...state.editingItemTypeRow,
          ...Object.fromEntries(
            Object.entries(payload).map(([key, val]) => {
              if (
                typeof state.editingItemTypeRow[key] === 'object' &&
                state.editingItemTypeRow[key]
              )
                // null is also an object so make sure not null
                return [
                  key,
                  {
                    ...state.editingItemTypeRow[key],
                    _id: val
                  }
                ];
              return [key, val];
            })
          )
        }
      };
    case EDIT_ITEM_FIELD:
      return {
        ...state,
        editedItemFields: {
          ...state.editedItemFields,
          ...payload
        },
        editingItemRow: {
          ...state.editingItemRow,
          ...Object.fromEntries(
            Object.entries(payload).map(([key, val]) => {
              if (typeof state.editingItemRow[key] === 'object' && state.editingItemRow[key])
                // null is also an object so make sure not null
                return [
                  key,
                  {
                    ...state.editingItemRow[key],
                    _id: val
                  }
                ];
              return [key, val];
            })
          )
        }
      };
    case ITEM_CREATE_SUCCESS:
      return {
        ...state,
        items: [],
        editingItemRow: null,
        editedItemFields: {}
      };
    default:
      return state;
  }
};
