import { KS_DEPTS_LOADED } from '../actions/types';

const initialState = {
  loadingKSDepts: true,
  ksDepts: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case KS_DEPTS_LOADED:
      return {
        ...state,
        loadingKSDepts: false,
        ksDepts: [...payload]
      };
    default:
      return state;
  }
};
