import {
  START_LOADING_PROJECTIDMAPPINGS,
  PROJECTIDMAPPINGS_LOAD_SUCCESS,
  PROJECTIDMAPPINGS_LOAD_FAILED,
  PROJECTIDMAPPINGS_LOAD_SKIPPED,
  PROJECTIDMAPPING_CREATE_SUCCESS,
  PROJECTIDMAPPING_UPDATE_SUCCESS,
  START_LOADING_PROJECTID_MIXIN_OPTIONS,
  PROJECTID_MIXIN_OPTIONS_LOAD_SUCCESS,
  PROJECTID_MIXIN_OPTIONS_LOAD_FAILED,
} from "../actions/types";

const initialState = {
  loadingProjectIdMappings: false,
  projectIdMappings: [],
  loadingMixinProjectIdOptions: false,
  mixinProjectIdOptions: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_LOADING_PROJECTIDMAPPINGS:
      return {
        ...state,
        loadingProjectIdMappings: true,
      };
    case PROJECTIDMAPPINGS_LOAD_SUCCESS:
      return {
        ...state,
        loadingProjectIdMappings: false,
        projectIdMappings: [...payload],
      };
    case PROJECTIDMAPPINGS_LOAD_FAILED:
    case PROJECTIDMAPPINGS_LOAD_SKIPPED:
      return {
        ...state,
        loadingProjectIdMappings: false,
      };
    case PROJECTIDMAPPING_CREATE_SUCCESS:
      return {
        ...state,
        projectIdMappings: [],
      };
    case PROJECTIDMAPPING_UPDATE_SUCCESS:
      return {
        ...state,
        projectIdMappings: state.projectIdMappings.map((v) => {
          if (v._id === payload._id) {
            return payload;
          }
          return v;
        }),
      };
    case START_LOADING_PROJECTID_MIXIN_OPTIONS:
      return {
        ...state,
        loadingMixinProjectIdOptions: true,
      };
   case PROJECTID_MIXIN_OPTIONS_LOAD_SUCCESS:
      return {
        ...state,
        loadingMixinProjectIdOptions: false,
        mixinProjectIdOptions:[...payload]
      };
    case PROJECTID_MIXIN_OPTIONS_LOAD_FAILED:
        return {
          ...state,
          loadingMixinProjectIdOptions: false,
        };
    default:
      return state;
  }
};
