import {
  USER_CREATE_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_GET_SUCCESS,
  USER_GET_FAILED,
  START_LOADING_USER,
  START_LOADING_USERS_PAGE,
  USERS_PAGE_LOAD_SUCCESS,
  USERS_PAGE_LOAD_FAILED,
  USERS_UPDATE_SORT,
  USERS_SET_FILTER,
  REFRESH_USERS,
  EXTERNAL_USERS_LOADED,
  EXTERNAL_USERS_LOAD_SKIPPED
} from '../actions/types';

const initialState = {
  filter: {},
  reverse: true,
  loadingUsers: false,
  total: 0,
  users: [],
  nextId: null,
  pageEnd: false,
  user: null,
  loadingUser: false,
  loadingExternalUsers: true, // load once in app only
  externalUsers: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  const clearData = {
    total: 0,
    users: [],
    nextId: null,
    pageEnd: false
  };
  switch (type) {
    case REFRESH_USERS:
    case USER_CREATE_SUCCESS:
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        ...clearData
      };
    case USER_GET_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        user: payload
      };
    case USER_GET_FAILED:
      return {
        ...state,
        loadingUser: false,
        user: null
      };
    case START_LOADING_USER:
      return {
        ...state,
        loadingUser: true
      };
    case START_LOADING_USERS_PAGE:
      return {
        ...state,
        loadingUsers: true
      };
    case USERS_PAGE_LOAD_SUCCESS:
      return {
        ...state,
        loadingUsers: false,
        total: payload.total,
        users: [...state.users, ...payload.data],
        nextId: payload.nextId,
        pageEnd: !payload.nextId
      };
    case USERS_PAGE_LOAD_FAILED:
      return {
        ...state,
        loadingUsers: false
      };
    case USERS_UPDATE_SORT:
      return {
        ...state,
        ...clearData,
        reverse: payload
      };
    case USERS_SET_FILTER:
      return {
        ...state,
        ...clearData,
        filter: payload
      };
    case EXTERNAL_USERS_LOADED:
      return {
        ...state,
        loadingExternalUsers: false,
        externalUsers: [...payload]
      };
    case EXTERNAL_USERS_LOAD_SKIPPED:
      return {
        ...state,
        loadingExternalUsers: false
      };
    default:
      return state;
  }
};
