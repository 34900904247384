import sendRequest from '../utils/sendRequest';
import handleResErr from '../utils/handleResErr';
import { createAlert } from '../actions/alert';
import { addUILoading, removeUILoading } from './ui';
import createConfirmPopup from '../utils/createConfirmPopup';
import contentDisposition from 'content-disposition';

export const downloadUserManual = type => async dispatch => {
  if (!Blob || !window.URL || !window.URL.createObjectURL) {
    return createConfirmPopup('UNSUPPORTED_BROWSER', null, {
      hasCancelBtn: false,
    });
  }
  dispatch(addUILoading());
  try {
    const res = await sendRequest('get', `/api/user_manual/${type}`, undefined, {
      responseType: 'blob',
    });
    const disposition = contentDisposition.parse(res.headers['content-disposition']);
    let blob = new Blob([res.data]);
    let fileName = disposition && disposition.parameters && disposition.parameters.filename;
    if (!fileName) {
      return dispatch(createAlert('UNKNOWN_ERROR'));
    }
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // for IE
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const url = window.URL.createObjectURL(blob);
      // create temp <a> element and trigger click to download
      let eleLink = document.createElement('a');
      eleLink.download = fileName;
      eleLink.style.display = 'none';
      eleLink.href = url;
      document.body.appendChild(eleLink);
      eleLink.click();
      document.body.removeChild(eleLink); // remove the temp element
    }
  } catch (err) {
    dispatch(handleResErr(err));
  } finally {
    dispatch(removeUILoading());
  }
};
