import {
  START_LOADING_ADMIN_SETTINGS,
  ADMIN_SETTINGS_LOAD_SUCCESS,
  ADMIN_SETTINGS_LOAD_FAILED,
  ADMIN_SETTINGS_UPDATE_SUCCESS
} from '../actions/types';

const initialState = {
  loadingAdminSettings: false,
  adminSettings: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_LOADING_ADMIN_SETTINGS:
      return {
        ...state,
        loadingAdminSettings: true
      };
    case ADMIN_SETTINGS_LOAD_SUCCESS:
      return {
        ...state,
        loadingAdminSettings: false,
        adminSettings: { ...payload }
      };
    case ADMIN_SETTINGS_LOAD_FAILED:
      return {
        ...state,
        loadingAdminSettings: false
      };
    case ADMIN_SETTINGS_UPDATE_SUCCESS:
      return {
        ...state,
        adminSettings: { ...payload }
      };
    default:
      return state;
  }
};
