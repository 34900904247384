import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import translate from '../../lang';
import { vsprintf } from 'sprintf-js';

const TranslateComponent = ({
  lang,
  msg,
  msgArgs = null,
  component: Component = Fragment,
  dispatch,
  ...rest
}) => {
  const outputMsg = msgArgs ? vsprintf(translate(msg, lang), msgArgs) : translate(msg, lang);
  return <Component {...rest}>{outputMsg}</Component>;
};

TranslateComponent.propTypes = {
  msg: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  lang: state.lang
});

export default connect(mapStateToProps)(TranslateComponent);
