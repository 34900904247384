import { supportedLang } from './const';

export default (lang) => {
  switch (lang) {
    case supportedLang.ZH_HK:
      return '繁體中文';
    case supportedLang.EN:
      return 'English';
    default:
      return '';
  }
}