import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { LoadingOverlayComponent as LoadingOverlay } from '../layout/LoadingOverlay';

const MySuspense = ({ lazyComponent }) => {
  return <Suspense fallback={<LoadingOverlay />}>{lazyComponent}</Suspense>;
};

MySuspense.propTypes = {
  lazyComponent: PropTypes.node.isRequired
};

export default MySuspense;
